@charset "utf-8";
@import "setting";
.pc {
  display: block;
  @include sp {
    display: none;
  }
}
.sp {
  display: none;
  @include sp {
    display: block;
  }
}
h1.hf-title-h1 {
  color: red;
  font-size: 100px;
  font-weight: bold;
  @include sp {
    font-size: 50px;
  }
}
/*【独自デザイン】イベント背景カスタマイズレイアウト*/
.bs-bg-custom {
  background: url("../_custom-bg-img/header-bg-pc.png") no-repeat center center / cover!important;
  @media screen and (max-width: 767px) {
    background: url("../_custom-bg-img/header-bg-sp.png") no-repeat center center / cover!important;
  }
  .header {
    background: url("../_custom-bg-img/header-bg-pc.png") no-repeat center center / cover!important;
  }
  .footer {
    background:none!important;
  }
}
.bs-color-custom {
  background: #201f1d!important;
  .gnav,
  .gnav__control--prev,
  .gnav__control--next {
    background: #201f1d!important;
  }
  @media screen and (max-width: 767px) {
    .gnav__inner,
    .gnav__list-link {
      background: #201f1d!important;
    }
  }
}
/*【独自デザイン】ヘッダーレイアウト(PC)*/
.bs-header-inner-pc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1010px;
  margin: 0 auto;
}
.bs-header-sns-pc {
  display: flex;
  align-items: center;
  li {
    &:not(:first-child) {
      margin-left: 20px;
    }
  }
}
/*【独自デザイン】ナビゲーションレイアウト(SP)*/
#gnav-sp .gnav__inner {
  background: var(--e-global-color-secondary);
}
#gnav-sp .gnav__list-link {
  background: var(--e-global-color-secondary);
}
#gnav-sp .gnav__list-link {
  font-weight: bold;
}
#gnav-sp .gnav__utilities a {
  background: var(--e-global-color-accent);
}
#gnav-sp .gnav__list .gnav-child__list > li > a {
  background: var(--e-global-color-accent);
  font-weight: bold;
  &:after {
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    opacity: 0.6;
  }
}
.bs-logo-image {
  width: auto;
  height: 25px;
  margin-right: 10px;
  img {
    filter: none!important;
    height: 100%;
  }
}
.bs-bnr-bpbdx {
  margin: 10px 32px 17px;
}
.bs-sns-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 60%;
  margin: 20px auto;
  li {
    margin: 5px 10px;
  }
}
/*【独自デザイン】第二階層入口ページ画像リンクリストレイアウト*/
.bs-image-link-list {
  display: flex;
  flex-wrap: wrap;
  .image-area {
    margin-left: 8%;
  }
  .text-area {
    width: 80%;
    background-color: #fff;
    margin-top: -8%;
    padding: 3% 3% 3% calc(3% + 3px);
    position: relative;
    .page-name {
      display: inline-block;
      border-bottom: 3px solid var( --e-global-color-secondary );
      padding-bottom: 3%;
      font-weight: bold;
      letter-spacing: 0.2em;
    }
    p {
      color: #000;
      font-size: 1.4rem;
      &:not(:first-child) {
        margin-top: 3%;
      }
    }
  }
  @include pc {
    a {
      transition: all ease 300ms;
      &:hover {
        opacity: 0.6;
        transition: all ease 300ms;
      }
    }
    &.column-one {
      li {
        padding-right: 7%;
        &:nth-child(n+2) {
          margin-top: 5%;
        }
        .page-name {
          font-size: 2.4rem;
          line-height: 3rem;
        }
      }
    }
    &.column-two {
      li {
        width: 50%;
        padding-right: 4%;
        &:nth-child(n+3) {
          margin-top: 4%;
        }
        .page-name {
          font-size: 2rem;
          line-height: 2.6rem;
        }
      }
    }
    &.column-three {
      li {
        width: 33.333%;
        padding-right: 2%;
        &:nth-child(n+4) {
          margin-top: 3%;
        }
        .page-name {
          font-size: 1.8rem;
          line-height: 2.2rem;
        }
      }
    }
    &.column-four {
      li {
        width: 25%;
        padding-right: 2%;
        &:nth-child(n+5) {
          margin-top: 2%;
        }
        .page-name {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }
    }
  }
  @include sp {
    &.sp-column-two {
      li {
        width: 50%;
        padding-right: 4%;
        &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(n+3) {
          margin-top: 4%;
        }
      }
    }
    li {
      padding-right: 8%;
      &:not(:first-child) {
        margin-top: 8%;
      }
    }
  }
}

/*【独自デザイン】アコーディオンレイアウト*/
.bs-accordion {
  +.bs-accordion {
    margin-top: -1px;
  }
  .bs-acc-title {
    background: #f2f3f5!important;
    border: 1px solid #d2d3d5;
    color: var( --e-global-color-secondary )!important;
    font-weight: bold;
  }
  .bs-acc-content {
    border: 1px solid #d2d3d5;
    margin-top: -1px;
    padding: 20px 16px;
  }
}

/*【独自デザイン】日付付きリストレイアウト*/
.bs-day-list {
  @include pc {
    display: flex;
    .list-day,.list-content {
      padding: 10px;
    }
    .list-day {
      width: 12%;
    }
    .list-content {
      width: 88%;
    }
  }
  @include sp {
    &:not(:first-child) {
      margin-top: 20px;
    }
    .list-day {
      border-bottom: 1px solid #000;
      padding-bottom: 8px;
      line-height: 1.4;
      word-break: break-all;
    }
    .list-content {
      padding-top: 8px;
    }
  }
}

/*【独自デザイン】汎用的複数カラムパターンレイアウト*/
.bs-column-list {
  display: flex;
  flex-wrap: wrap;
  img {
    display: block;
    margin: 0 auto;
  }
  img {
    word-break: break-all;
    &:not(:first-child) {
      margin-top: 4%;
    }
  }
  &.no-margin {
    &>* {
      margin-top: 0!important;
    }
  }
  @include pc {
    &.pc-column-ss {
      justify-content: space-between;
      &.image-20 {
        .image {
          width: 20%;
        }
        .text {
          width: 75%;
        }
      }
      &.image-25 {
        .image {
          width: 25%;
        }
        .text {
          width: 70%;
        }
      }
      &.image-33 {
        .image {
          width: 33%;
        }
        .text {
          width: 62%;
        }
      }
    }
    &.pc-column-1 {
      &>* {
        width: 100%;
        &:not(:first-child) {
          margin-top: 8%;
        }
      }
    }
    &.pc-column-2 {
      &>* {
        width: 48%;
        &:nth-child(2n) {
          margin-left: 4%;
        }
        &:nth-child(n+3) {
          margin-top: 4%;
        }
      }
    }
    &.pc-column-3 {
      &>* {
        width: 31%;
        &:not(:nth-child(3n+1)) {
          margin-left: 3.5%;
        }
        &:nth-child(n+4) {
          margin-top: 3.5%;
        }
      }
    }
    &.pc-column-4 {
      &>* {
        width: 23.5%;
        &:not(:nth-child(4n+1)) {
          margin-left: 2%;
        }
        &:nth-child(n+5) {
          margin-top: 2%;
        }
      }
    }
    &.pc-column-5 {
      &>* {
        width: 18.5%;
        &:not(:nth-child(5n+1)) {
          margin-left: 1.875%;
        }
        &:nth-child(n+6) {
          margin-top: 1.875%;
        }
      }
    }
    &.pc-column-6 {
      &>* {
        width: 15.666%;
        &:not(:nth-child(6n+1)) {
          margin-left: 1.2%;
        }
        &:nth-child(n+7) {
          margin-top: 1.2%;
        }
      }
    }
  }
  @include sp {
    &.sp-column-1 {
      &>* {
        width: 100%;
        &:not(:first-child) {
          margin-top: 8%;
        }
      }
    }
    &.sp-column-2 {
      &>* {
        width: 48%;
        &:nth-child(2n) {
          margin-left: 4%;
        }
        &:nth-child(n+3) {
          margin-top: 4%;
        }
      }
    }
    &.sp-column-3 {
      &>* {
        width: 31%;
        &:not(:nth-child(3n+1)) {
          margin-left: 3.5%;
        }
        &:nth-child(n+4) {
          margin-top: 4%;
        }
      }
    }
  }
}

/*【独自デザイン】枠レイアウト*/
.bs-border-box {
  border-radius: 6px 6px 6px 6px;
  padding: 20px 20px 20px 20px;
  &.bg-white {
    background-color: #fff;
    border: 1px solid #000121;
  }
  &.bg-red {
    background-color: #FFF8F8;
    border: 2px solid #BB0000;
  }
  &.bg-gray {
    background-color: #FAFAFA;
    border: 2px solid #000121;
  }
}

/*【独自デザイン】イベントスケジュールレイアウト*/
.bs-current-area {
  display: flex;
  p {
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
    @include sp {
      font-size: 24px;
    }
    span {
      display: inline-block;
      margin-left: 4px;
      font-size: 0.4em;
    }
    &+p {
      margin-left: 10px;
    }
  }
}
.bs-select-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 10%;
  margin: -60px 0 0 auto;
  @include sp {
    width: 50%;
    margin: -55px 0 0 auto;
  }
  select {
    width: 160px;
    @include sp {
      width: calc((100% - 10px) / 2);
    }
  }
  select + select {
    margin-left: 10px;
  }
}
.bs-event-schedule {
  &-block {
    border-top: 1px solid #777777;
    padding: 40px 0;
    @include pc {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
    }
    @include sp {
      padding: 40px 5%;
    }
  }
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bs-day-area {
      @include pc {
        width: 80px;
      }
      @include sp {
        width: 15%;
      }
      p {
        font-weight: bold;
        text-align: center;
      }
      .bs-day {
        font-size: 30px;
        @include sp {
          font-size: 20px;
        }
      }
      .bs-week {
        @include sp {
          font-size: 14px;
        }
        &.sat {
          color: #0093FF;
        }
        &.sun {
          color: #FF0000;
        }
      }
    }
    .bs-game-area {
      @include pc {
        width: 240px;
      }
      @include sp {
        display: flex;
        flex-wrap: wrap;
        width: 85%;
      }
      .bs-team-info {
        display: flex;
        align-items: center;
        @include sp {
          width: 60%;
        }
        .bs-team {
          width: 60px;
          margin-right: 20px;
          @include sp {
            width: 40px;
            margin-right: 10px;
          }
        }
        .bs-info {
          font-weight: bold;
          @include sp {
            font-size: 13px;
          }
        }
      }
      .bs-info-btn {
        display: block;
        width: 100%;
        background: #000121;
        border-radius: 30px;
        margin-top: 20px;
        color: #fff;
        line-height: 1.6;
        position: relative;
        @include sp {
          width: 48%;
          margin-top: 10px;
        }
        &:after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-right: 2px solid #fff;
          border-top: 2px solid #fff;
          position: absolute;
          right: 15px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          transform: rotate(45deg);
          transition: all ease 300ms;
        }
        &.inactive {
          pointer-events: none;
          background: #7f7f7f;
        }
        span {
          display: block;
          padding: 10px 30px;
          text-align: center;
          @include sp {
            font-size: 12px;
          }
        }
        @include pc {
          transition: all ease 300ms;
          &:hover {
            opacity: 0.8;
            transition: all ease 300ms;
            &:after {
              right: 13px;
              transition: all ease 300ms;
            }
          }
        }
      }
    }
    .bs-uniform-area {
      @include pc {
        width: 200px;
        position: absolute;
        top: 20px;
        right: 0;
      }
      @include sp {
        width: 40%;
      }
    }
    @include sp {
      .btn-area {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  &-body {
    @include pc {
      width: calc(100% - 360px);
    }
    .bs-icon-area {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: calc(100% - 240px);
      @include sp {
        width: 100%;
        margin-top: 20px;
        justify-content: center;
      }
      li {
        width: calc(100% / 6);
        &:nth-child(n+7) {
          margin-top: 8px;
        }
        a {
          display: block;
          padding: 0 4px;
          @include pc {
            transition: all ease 300ms;
            &:hover {
              background: none!important;
              opacity: 0.8;
              transition: all ease 300ms;
            }
          }
        }
      }
    }
    .bs-news-area {
      margin-top: 20px;
      .bs-news-list {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:before {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-top: 2px solid #000121;
            border-right: 2px solid #000121;
            transform: rotate(45deg);
          }
          a {
            display: block;
            width: calc(100% - 20px);
          }
          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
.bs-event-schedule-wrap {
  &.ver-2024 {
    .bs-event-schedule-block {
      border-top: 1px solid #777777;
      padding: 40px 0;
      @include pc {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
      }
      @include sp {
        padding: 40px 5%;
      }
    }
    .bs-event-schedule-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include pc {
        width: 45%;
      }
      .bs-day-area {
        @include pc {
          width: 80px;
        }
        @include sp {
          width: 15%;
        }
        p {
          font-weight: bold;
          text-align: center;
        }
        .bs-day {
          font-size: 30px;
          @include sp {
            font-size: 20px;
            text-align: left;
          }
        }
        .bs-week {
          @include sp {
            font-size: 14px;
            text-align: left;
          }
          &.sat {
            color: #0093FF;
          }
          &.sun {
            color: #FF0000;
          }
        }
      }
      .bs-game-area {
        @include pc {
          width: 100%;
        }
        @include sp {
          display: flex;
          flex-wrap: wrap;
          width: 85%;
        }
        .bs-team-info-wrap {
          @include sp {
            width: 100%;
            min-height: 60px;
            position: relative;
            overflow: hidden;
          }
        }
        .bs-team-info {
          @include pc {
            display: flex;
            justify-content: space-between;
          }
          @include sp {
            display: block;
            width: 60%;
          }
          .bs-team-info-area {
            display: flex;
            align-items: center;
          }
          .bs-team {
            width: 60px;
            margin-right: 20px;
            @include sp {
              width: 40px;
              margin-right: 10px;
            }
          }
          .bs-info {
            font-size: 15px;
            font-weight: bold;
            @include sp {
              font-size: 12px;
            }
          }
          .bs-icon-area {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @include pc {
              width: 50%;
            }
            @include sp {
              width: 100%;
              margin-top: 10px;
              justify-content: center;
            }
            li {
              width: calc(100% / 3);
              &:nth-child(n+7) {
                margin-top: 8px;
              }
              a {
                display: block;
                padding: 0 4px;
                @include pc {
                  transition: all ease 300ms;
                  &:hover {
                    background: none!important;
                    opacity: 0.8;
                    transition: all ease 300ms;
                  }
                }
              }
            }
          }
        }
        .bs-info-btn {
          display: block;
          width: 48%;
          background: #000121;
          border-radius: 30px;
          margin-top: 20px;
          color: #fff;
          line-height: 1.6;
          position: relative;
          @include sp {
            margin-top: 10px;
          }
          &:after {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-right: 2px solid #fff;
            border-top: 2px solid #fff;
            position: absolute;
            right: 15px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            transform: rotate(45deg);
            transition: all ease 300ms;
          }
          &.inactive {
            pointer-events: none;
            background: #7f7f7f;
          }
          span {
            display: block;
            padding: 10px 20px;
            text-align: center;
            @include sp {
              font-size: 12px;
            }
          }
          @include pc {
            transition: all ease 300ms;
            &:hover {
              opacity: 0.8;
              transition: all ease 300ms;
              &:after {
                right: 13px;
                transition: all ease 300ms;
              }
            }
          }
        }
      }
      .bs-uniform-area {
        @include pc {
          width: 160px;
          height: 85px;
          position: absolute;
          top: 20px;
          right: 0;
          z-index: -1;
          overflow: hidden;
        }
        @include sp {
          width: 40%;
          height: 100%;
          min-height: 60px;
          overflow: hidden;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      .btn-area {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .bs-event-schedule-body {
      @include pc {
        width: 50%;
      }
      .bs-news-area {
        margin-top: 65px;
        @include sp {
          margin-top: 10px;
        }
        .bs-news-list {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:before {
              content: '';
              display: block;
              width: 8px;
              height: 8px;
              border-top: 2px solid #000121;
              border-right: 2px solid #000121;
              transform: rotate(45deg);
            }
            a {
              display: block;
              width: calc(100% - 20px);
              font-size: 14px;
            }
            &:not(:first-child) {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}

/*【独自デザイン】トップ3連戦エリア*/
.bs-top-special-match-area {
  .bs-special-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding: 10px 0;
    @include sp {
      padding: 10px 3%;
    }
    &:first-child {
      border-top: 1px solid #000;
    }
    .bs-special-day {
      width: 60px;
      font-size: 20px;
      font-weight: bold;
    }
    .bs-special-box {
      width: calc(100% - 60px);
    }
    @include pc {
      .bs-special-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .bs-special-info {
        width: calc(55% - 20px);
      }
      .bs-special-link {
        width: 45%;
      }
    }
  }
  .bs-special-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .bs-special-time {
      display: flex;
      align-items: center;
      font-weight: bold;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        background: #E6E6E6;
        margin-right: 10px;
        font-size: 14px;
        &.sat {
          background: #0093FF;
          color: #fff;
        }
        &.sun {
          background: #FF0000;
          color: #fff;
        }
      }
    }
  }
  .bs-special-icon {
    width: 40px;
    height: auto;
  }
  .bs-special-name {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.2;
    span {
      display: block;
    }
  }
  .bs-special-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .bs-info-btn {
    display: block;
    width: 48%;
    background: #000121;
    border-radius: 30px;
    padding: 2px 0 4px;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    position: relative;
    @include sp {
      margin-top: 10px;
    }
    &:after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-right: 2px solid #fff;
      border-top: 2px solid #fff;
      position: absolute;
      right: 15px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      transform: rotate(45deg);
      transition: all ease 300ms;
    }
    &.inactive {
      pointer-events: none;
      background: #7f7f7f;
    }
    span {
      display: block;
      padding: 10px 10px;
      text-align: center;
      @include sp {
        font-size: 12px;
      }
    }
    @include pc {
      transition: all ease 300ms;
      &:hover {
        opacity: 0.8;
        transition: all ease 300ms;
        &:after {
          right: 13px;
          transition: all ease 300ms;
        }
      }
    }
  }
}

/*【独自デザイン】Youtubeレスポンシブ（ニュースと同じ）*/
.youtube {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* 【独自デザイン】モーダル表示調整 */
.elementor-popup-modal .dialog-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100%;
  border: 2px solid #7a7a7a;
  margin: 0 auto;
  top: auto!important;
  bottom: -50px!important;
  right: 0!important;
  left: 0!important;
}
#elementor-popup-modal-249476 .dialog-message {
  width: 960px!important;
}

/* 【独自デザイン】画面固定バナー */
.bs-fixed-bnr {
  width: 140px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 101;
  img {
    width: 100%;
  }
  a {
    transition: all $ease 300ms;
    &:hover {
      opacity: 0.7;
      transition: all $ease 300ms;
    }
  }
  @include sp {
    width: 100%;
    bottom: 0;
    right: 0;
    img {
      width: 100%;
    }
  }
}

/* 【独自デザイン】トップページアイコン */
.bs-page-icon-list {
  display: flex;
  li {
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    &:last-child {
      border-right: 1px solid #fff;
    }
  }
}

/* 【独自デザイン】トップページtiktok */
.tiktok-list {
  padding: 0 30px;
  position: relative;
  .swiper-button-next {
    background: none;
    //right: -40px;
  }
  .swiper-button-prev {
    background: none;
    //left: -40px;
  }
  .tiktok-embed {
    border-radius: 8px;
  }
}

/* 【独自デザイン】ニュース一覧と選手名鑑ページにおけるソート機能の画面固定（SPのみ） */
@include sp {
  // 選手名鑑
  .page-id-3393,.page-id-90012307 {
    .c-player-directory__control {
      position: sticky;
      top: 56px;
      z-index: 2;
      background: #fff;
      padding: 6px 5%;
      margin: 0;
    }
  }
  // ニュース一覧
  .page-id-3311,.page-id-90007558 {
    .elementor-element-60f69e09 {
      p+.c-content {
        background: #fff;
        padding: 0 5% 10px;
        position: sticky;
        top: 56px;
        z-index: 2;
        .c-datecontrol {
          margin: 0;
        }
      }
    }
  }
}

// 【独自デザイン】多言語切り替え
.language-button {
  border: none;
  padding: 0;
  &:hover,&:focus,&:focus-visible {
    background-color: inherit;
    border: none;
    opacity: 1;
    outline: inherit;
  }
  &-wrap {
    position: relative;
    #msta_langArea {
      opacity: 0;
      width: 20rem;
      background-color: #EEEEEE;
      border-radius: 5px;
      padding: 1rem 1.4rem;
      transition: opacity 0.3s ease-out;
      transform: translateY(calc(tan(60deg) * 13px / 2 + 100%));
      position: absolute;
      bottom: 0;
      left: 0;
      @include sp {
        width: 100%;
      }
      &::before {
        content: '';
        display: block;
        height: calc(tan(60deg) * 13px / 2);
        width: 13px;
        background-color: #EEEEEE;
        clip-path: polygon(50% 0, 100% 100%, 0 100%);
        position: absolute;
        left: 2rem;
        top: calc(tan(-60deg) * 13px / 2 + 1px);
        @include sp {
          margin: 0 auto;
          left: 0;
          right: 0;
        }
      }
    }
  }
  &.is-active {
    background-color: inherit;
    border: none;
    opacity: 1;
    +#msta_langArea {
      opacity: 1;
    }
  }
}
