@charset "utf-8";

// ---------------------------------------------
// https://www.npmjs.com/package/css-modules-hf
// 共通ユーティリティーCSS
// ---------------------------------------------
@use "css-modules-hf/dist/modules.css";

// サイト横幅
$w-site: 1080px;
// ブレイクポイント
$w-sp: 767px;
// 標準イージング
$ease: cubic-bezier(0.215, 0.61, 0.355, 1);

// Media query
@mixin sp() {
  @media screen and (max-width: $w-sp) {
    @content;
  }
}
@mixin pc() {
  @media screen and (min-width: $w-sp+1) {
    @content;
  }
}
